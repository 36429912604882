import React from 'react';

import Layout from '@components/layout';

import HeroSection from '@components/hero-section';
import { botTokens } from '@images';
import FreePricing from '@components/free-pricing';
import SEO from '@components/seo';

const Ecosystem = () => {
  return (
    <Layout headerClass="absolute z-50">
      <SEO
        description="Learn about running costs for AI automation for DEX trading"
        title="BotSwap DeFi automation: ongoing costs"
      />
      <main>
        <HeroSection
          title={<span className="block">Pricing</span>}
          subTitle={
            <>
              Easy setup of trade automation. <br />
              Free to try, simple to maintain
            </>
          }
          img={botTokens}
        />
        <FreePricing />
      </main>
    </Layout>
  );
};  

export default Ecosystem;
