import React from 'react';

import {
  walkthoughCards,
} from '../costs/costs.content';
import Card from '@components/card';
import TryBotswap from '../try-botswap';

const FreePricing = () => {
  return (
    <div className="bg-white pt-12 sm:pt-20">
      <div className="relative py-1">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <dl className="rounded-lg bg-white gap-x-14 px-5 sm:px-0 sm:grid sm:grid-cols-3">
              {walkthoughCards.map((card) => (
                <Card
                  key={card.id}
                  img={card.img}
                  title={card.title}
                  rounded={true}
                />
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className='pt-12'>
      <TryBotswap />
      </div>
    </div>
    );
};

export default FreePricing;