import { botPink, coinBox, configureIcon } from '@images';

export const walkthoughCards = [
  {
    id: 1,
    img: botPink,
    title: 'Connect Wallet',
  },
  {
    id: 2,
    img: coinBox,
    title: 'Configure Triggers',
  },
  {
    id: 3,
    img: configureIcon,
    title: 'Manage and Optimize',
  },
];

export const costsTableDataHeading = ['', 'One agent', 'Two agents', 'Five agents'];

export const costsTableData = [
  ['Cost per day', '1 FET', '2 FET', '5 FET'],
  ['Cost per week', '7 FET', '14 FET', '35 FET'],
  ['Cost per month', '30 FET', '60 FET', '150 FET'],
];
